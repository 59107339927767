
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/*-------Commomn css---------*/
*{margin:0px; padding:0px;}
body {background:url("../src/assets/images/bg-pattren.png"); font-family: "Outfit", sans-serif; font-weight:400; font-size:18px; line-height:1.5; position:relative;}
body:before {content:''; background:rgba(255,255,255,0.9); position:absolute; top:0px; bottom:0px; left:0px; right:0px; z-index:-1;}
body:after {content:''; position:absolute; top:18%; bottom:0px; left:0px; right:0px; z-index:-1;
background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 18%,rgba(255,255,255,1) 100%);
}
a {text-decoration:none;}

h1{font-family: 'yapari-med'; font-size:52px; font-weight:500; color:#8338DC}
h2{font-family: 'yapari-bold'; font-size:39px; font-weight:700;}
h2 span{font-family: 'yapari'; font-weight:400;}
h3{font-size:22px; font-weight:600; color:#8338DC!important;}

h5{font-size:16px; font-weight:500;}

.fs-1{font-size:34px!important;}
.fs-2{font-size:26px!important;}
.fs-3{font-size:24px!important;}
.fs-5{font-size:16px!important; color:#464141;}
.fs-6{font-size:12px!important;}

.font-Yapari {font-family: 'Yapari Med';}
.font-outfit {font-family: "Outfit", sans-serif; color: #8338DC !important;}

.sep-line {width:315px;}
.sep-line:before {content:''; background:#000; height:15px; width:15px; border-radius:100%; display:block; margin-bottom:-15px;}
.sep-line:after {content:''; background:#000; height:15px; width:15px; border-radius:100%; display:block; margin-bottom:-8px; margin-left:auto;}

.btn-lg {font-weight:500; font-size:20px; padding:13px 50px;}

#scroll {position:fixed; right:10px; bottom:10px; cursor:pointer; width:50px; height:50px; line-height:50px; border-radius:60px; text-align:center; z-index:99;}
#scroll:hover{background:#000!important;}


/*-------Color theme css---------*/
.bg-primary {background-color:#8338DC !important;}
.text-primary {color:#8338DC !important;}
.btn-primary {background-color:#8338DC !important; border-color:#8338DC !important;}
.btn-primary:hover {background-color:#521B96 !important; border-color:#521B96 !important;}
.border-primary{border-color:#8338DC !important; border-radius:20px !important}

.bg-dark {background-color:#3a3a3a !important;}
.text-dark {color:#3a3a3a !important;}
.btn-dark {background-color:#3a3a3a !important; border-color:#3a3a3a !important;}
.btn-dark:hover {background-color:#2d2d2d !important; border-color:#2d2d2d !important;}

.btn-black {background-color:#000 !important; border-color:#000 !important; color:#fff !important;}
.btn-black:hover {background-color:#2d2d2d !important; border-color:#2d2d2d !important;}

.bg-secondary {background-color:#E1E1E1 !important;}
.text-secondary {color:#E1E1E1 !important;}
.btn-secondary {background-color:#000 !important; border-color:#000 !important;}
.btn-secondary:hover {background-color:#8B8B8B !important; border-color:#8B8B8B !important;}



/*-------Header css---------*/
#header .logo {border-radius: 100%; padding: 278px 31px 0 36px; display: inline-block; margin: -281px 0px -8px 36px; height: 384px; width: 385px; overflow: hidden;}
#header .toggle-btn {height:65px; width:65px; cursor:pointer;}

#nav .offcanvas-header .btn-close {background-color:#8338DC !important; color:#fff !important; border-color:#8338DC !important; width: 50px; height: 50px;top: 62px;
    left: 10px; border-radius: 50px; margin-left: -50px; opacity:1;}
#nav .offcanvas-header .offcanvas-title{margin-left:12px;}

 #nav .offcanvas-body > p a:hover ,#nav .offcanvas-body > p > a.active ,#nav .offcanvas-body > p.active > a { color: #fff !important;background: #8338dc;}
#nav .offcanvas-footer-sec a {color:#000 !important; margin-top:-9x;}
#nav .offcanvas-footer-sec a:hover {color:#8338DC !important;}

#nav-toggle {display: inline-block; height: 20px; width: 25px; position: relative;}
#nav-toggle span {margin-top: 3px;}
#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {cursor: pointer; border-radius: 1px; width: 25px; background: #fff; position: absolute; display: block; content: '';}
#nav-toggle span:before {top: -5px; height: 3px;}
#nav-toggle span:after {bottom: -5px; height: 3px;}


/*-------Main css---------*/
#portfolio .rounded-5 {border-radius:50px !important;}
#portfolio .btm-arrow {height:63px; width:63px; line-height:63px; cursor:pointer; margin-top:-60px;}
#portfolio .btm-arrow:hover {background-color:#8338DC !important;}
#portfolio .btm-arrow:hover .bi {color:#fff !important;}

#portfolio .card .card-img {height:325px;}
#portfolio .card .card-img:after {content:''; background-color:rgba(0,0,0,0.7); position:absolute; top:0px; left:0px; bottom:0px; right:0px;}
#portfolio .card:hover .card-img:after {background-color:rgba(108,68,155,0.7);}
#portfolio .card .card-img .partner-logo {z-index:2;}
#portfolio .card .card-body {font-size:16px; color:#4E494A;margin-top:-20px;}

#lets-talk:before {content:''; display:block; background:url("../src/assets/images/logo-watermark.svg") no-repeat; background-size:200px; opacity:.1; width:200px; height:100px; position:absolute; bottom:3%; right:5%;}
#lets-talk{border-radius:50px !important;}


/*-------Footer css---------*/
#footer a {color:#000;}
#footer a:hover {color:#8338DC;}



/*----------------------portfolio page CSS-------------------*/

#portfolio-details .sep-line {width:268px;}
#portfolio-details .sep-line:before {height:10px; width:10px; margin-bottom:-9px;}
#portfolio-details .sep-line:after {height:10px; width:10px; margin-bottom:-6px;}

#portfolio-details ul.d-flex{height:72px;margin: 0; padding: 0;}
#portfolio-details .icons{height:25px;}
#portfolio-details .tools li{font-size: 16px;text-align: center;}
#portfolio-details .tools li+li{margin-left:10px;}

.lr-pattren-line .left-line {background:url("../src/assets/images/left-head-line.png") no-repeat 0 0; display:inline-block; height:34px; width:146px; margin-bottom:-5px; margin-right:20px; margin-top:50px;}
.lr-pattren-line .right-line {background:url("../src/assets/images/right-head-line.png") no-repeat 0 0; display:inline-block; height:34px; width:146px; margin-bottom:-5px; margin-left:20px; margin-top:50px;}

#case-study .portfolio-abbey .abbey-img{height:415px; object-fit:cover;}
#case-study .portfolio-abbey:after {content:''; background-color:rgba(0,0,0,0.7); position:absolute; top:0px; left:0px; bottom:0px; right:0px;}
#case-study .portfolio-abbey:hover::after {content:''; background-color:rgba(108,68,155,0.7);}
#case-study .portfolio-abbey .partner-logo {z-index:1;}


#testimonial .bubble-shape:before {border-bottom: 35px solid #F7F6F6; border-left: 50px solid transparent; border-right: 50px solid transparent; content: "";  height:0; left: 50%; transform: translateX(-50%); position: absolute; top:-35px; width:0;}
  





/*-------Media css---------*/
@media (min-width:992px) {
	#portfolio .btm-arrow {margin-top:-80px;}	
}

@media (max-width:1199.98px) {
	#header .toggle-btn {left: auto !important; right: 0px;}
}

@media (max-width:767.98px) {
	#portfolio .card .card-img {height:250px;}
	
	
	#testimonial .bubble-shape {padding: 15px;}
  #testimonial .bubble-shape p {font-size: 16px;}
}

@media (max-width:575.98px) {
	h1 {font-size: 220%;}
	h2 {font-size: 156%;}
	#header .logo {margin: -280px 0px -8px 0px; height: 384px; width: 325px;}
	
	
.lr-pattren-line .left-line {width:80px;}
.lr-pattren-line .right-line {background-position:right; width:80px;}
}

@media (max-width:399.98px) {
	h1 {font-size: 170%;}
	h2 {font-size: 106%;}
	#header .logo {padding: 228px 31px 0 36px; margin: -222px 0px -8px 0; height: 300px; width: 237px;}
	#header .logo img {width:100%;}
	.sep-line {width: 235px;}
	.sepr-line{width: 152px}
	#lets-talk {padding-bottom:150px!important;}
.lr-pattren-line .left-line {width:50px;}
.lr-pattren-line .right-line {background-position:right; width:50px;}
}

/*----------------------Case study page CSS-------------------*/
#case-study-details .sep-line {width:268px;}
#case-study-details .sep-line:before {height:10px; width:10px; margin-bottom:-9px;}
#case-study-details .sep-line:after {height:10px; width:10px; margin-bottom:-6px;}

.conclusion-section{border-radius: 20px !important;}

/*-------Media css---------*/
@media (min-width:992px) {
	#visuals .outer-gal-max-height {max-height:768px;}
	#portfolio .btm-arrow {margin-top:-80px;}	
}
@media (min-width:576px) {
	#visuals .outer-gal-max-height {max-height:768px;}
}

@media (max-width:1199.98px) {
	#header .toggle-btn {left: auto !important; right: 0px;}
	
	#visuals .gal-object {height:319px;}
	#visuals .gal-object.gal-min-height {height:98px;}
	#visuals .gal-object.gal-min2-height {height:80px;}
	#visuals .gal-object.gal-max-height {height:407px;}
}

@media (max-width:767.98px) {
	#portfolio .card .card-img {height:250px;}

	#testimonial .bubble-shape {padding: 15px;}
    #testimonial .bubble-shape p {font-size: 16px;}
}

@media (max-width:575.98px) {
	h1 {font-size: 220%;}
	h2 {font-size: 156%;}
	#header .offcanvas {max-width:80% !important;}
	#header .logo {margin: -280px 0px -8px 0px; height: 384px; width: 325px;}
	
	.lr-pattren-line .left-line {width:80px;}
	.lr-pattren-line .right-line {background-position:right; width:80px;}
}

@media (max-width:399.98px) {
	h1 {font-size: 170%;}
	h2 {font-size: 106%;}
	#header .logo {padding: 228px 31px 0 36px; margin: -222px 0px -8px 0;height: 332px;width: 300px;}
	#header .logo img {width:100%;}
	#header .toggle-btn {height: 50px; width: 50px;cursor: pointer;}

	#nav .offcanvas-header .btn-close {width: 20px;height: 20px; border-radius: 50px; margin-left: -16px;}
	.sep-line {width: 235px;}
	
	#lets-talk {padding-bottom:150px!important;}
	.lr-pattren-line .left-line {width:50px;}
	.lr-pattren-line .right-line {background-position:right; width:50px;}
}