@font-face {
    font-family: 'yapari';
    src: url('YapariTrial.woff2') format('woff2'),
        url('YapariTrial.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'yapari-med';
    src: url('YapariTrial-Medium.woff2') format('woff2'),
        url('YapariTrial-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'yapari-bold';
    src: url('YapariTrial-Bold.woff2') format('woff2'),
        url('YapariTrial-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
